import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import CareerCard from '../components/CareerCard';
import { Section, SectionTitle } from '../components/Section';
import { Card, CardContent } from '../components/Card';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

const CareerPage = ({ data }) => {
  let [allJobs, setAllJobs] = useState(data?.allStrapiJob?.nodes);
  let [filteredJobs, setFilteredJobs] = useState(allJobs);

  const updateJobs = (e) => {
    filteredJobs = allJobs.filter(searchFilter(e.target.value));
    setFilteredJobs(filteredJobs);
  };

  useEffect(() => {
    fetch('https://strapi.lightfeather.io/jobs/', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((response) => response.json())
      .then((resultData) => {
        setAllJobs(resultData);
        setFilteredJobs(resultData);
      });
  }, []);

  return (
    <Layout>
      <Helmet>
        <meta charSet='utf-8' />
        <title>LightFeather - Careers</title>
      </Helmet>
      <Section>
        <SectionTitle headingLevel='h1' className='text-blue'>
          Careers
        </SectionTitle>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1'>
            <Card className='shadow-none prose lg:prose-lg max-w-none'>
              <CardContent className='font-bold text-center'>
                <h2>
                  Do you love <span className='text-blue-lighter'>innovation</span>? Do you want to
                  get stuff <span className='text-blue-lighter'>done</span>?
                </h2>
              </CardContent>
              <CardContent className={`mt-4`}>
                We want our employees to be excited to come into work every day, and we encourage
                LightFeather team members to explore their passions both in and out of the office
                and empower them to learn new things and do things they are passionate about. Our
                people make us who we are, and we recruit talented individuals who not only believe
                in our mission, but who are excited to be part of our unique team.
              </CardContent>
              <CardContent className={`mt-4`}>
                We don’t take ourselves too seriously, and we work hard to build a culture that is
                fun, creative, and family-friendly. So what gets you out of bed every morning? We
                hope it will be LightFeather!
              </CardContent>
            </Card>
          </div>
        </div>
      </Section>

      <Section className='bg-primary'>
        <SectionTitle headingLevel='h1' className='text-white'>
          <div className='container mx-auto'>
            <div className='grid grid-cols-1 '>
              <div className='text-left mx-6'>Career Opportunities</div>
            </div>
          </div>
        </SectionTitle>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1'>
            <div className='px-5 py-5'>
              <div className='bg-white shadow p-4 flex'>
                <span className='w-auto flex justify-end items-center text-gray-500 p-2'>
                  <FontAwesomeIcon icon={faSearch} size='lg' />
                </span>
                <input
                  className='w-full rounded p-2'
                  type='text'
                  placeholder='Enter Job By Title'
                  onChange={updateJobs}
                ></input>
              </div>
            </div>
          </div>
          <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-auto text-blue'>
            {filteredJobs.length > 0 ? (
              filteredJobs.map((document) => (
                <CareerCard
                  key={document.id.toString()}
                  career={{
                    title: document.jobTitle,
                    content: document.homepageBlurb,
                    id: document.id.toString()
                  }}
                ></CareerCard>
              ))
            ) : (
              <p className='py-15 text-white text-2xl'> No jobs matching that title were found</p>
            )}
          </div>
        </div>
      </Section>
    </Layout>
  );
};

/**
 * Returns a filter function for the jobs array based on the provided search value.
 * Generally does input cleaning before doing any comparisons.
 * @param {string} searchValue - user provided input text
 * @return {(jobDocument) => boolean}
 */
export const searchFilter = (searchValue) => {
  const cleanerFunction = (s) => {
    return s.toLowerCase().replace(/[^a-z\d]/g, '');
  };
  const cleanSearchValue = cleanerFunction(searchValue);
  return (jobDocument) => {
    return cleanerFunction(jobDocument.jobTitle).includes(cleanSearchValue);
  };
};

export default CareerPage;

export const pageQuery = graphql`
  query {
    allStrapiJob {
      nodes {
        id
        jobTitle
        homepageBlurb
        qualifications
        applicationLink
      }
    }
  }
`;
