import React, { useState } from 'react';
import { Link } from 'gatsby';
import Button from '../components/Button';

const CareerCard = ({ career }) => {
  const PREVIEW_LENGTH = 250;
  const [isOpen, setIsOpen] = useState(false);
  const needsPreview = career.content.length > PREVIEW_LENGTH;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const truncate = (text) => {
    return text.length > PREVIEW_LENGTH ? text.substring(0, PREVIEW_LENGTH) + '...' : text;
  };

  return (
    <div
      className={`relative p-12 rounded-lg border border-solid border-gray-200 md:max-w-410-px m-5 md:ml-5 md:mb-5 bg-white shadow-2xl transform ease-in-out duration-500 hover:-translate-y-1 hover:scale-110 elevation-24`}
    >
      <p className='text-xl font-semibold min-h-75-px'>{career.title}</p>
      {!needsPreview ? (
        <span className='mt-6 text-black'>{career.content}</span>
      ) : (
        <>
          <span className='mt-6 text-black'>
            {isOpen ? `${career.content} ` : `${truncate(career.content)} `}
          </span>
          <button onClick={toggle}>{isOpen ? 'Show Less' : 'Show More'}</button>
        </>
      )}
      <div className='flex items-center mt-8 pb-10 pt-10'>
        <div className='mt-8 md:mt-12 text-xl absolute bottom-0 right-0 pr-10 pb-10'>
          <Link to={`/job/${career.id.replace('Job_', '')}`}>
            <Button>Apply Now</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CareerCard;
